import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'screens/shared/Layout'
import Head from 'components/modules/Head'
import Post from 'components/post'
import BlogNav from 'components/blog-nav'
import { postContainer, container } from 'components/core/Layout'
import { Box } from 'components/primitives'
import Heading from 'components/core/Heading'
import Image from 'components/core/Image'
import PostCard from 'components/post-card'
import { Row, Column } from 'components/core/Layout'
import Divider from 'components/core/Divider'

export default ({ data: { post, otherPosts } }) => {
  return (
    <Layout>
      <Head title={post.title} />
      <BlogNav />
      <Box py={5} {...postContainer}>
        <Heading fontSize={5} fontWeight='heavy' mb={3}>
          {post.title}
        </Heading>
        <Image mb={4} src={post.feature_image} />
        <Post html={post.html} />
      </Box>
      <Box {...container}>
        <Heading fontWeight='heavy' mb={3}>
          인기있는 글
        </Heading>
        <Divider mb={4} />
        <Row>
          {otherPosts.edges.map(({ node }) => (
            <Column width={[1, 1 / 2, null, 1 / 3]} key={node.uuid}>
              <PostCard post={node} />
            </Column>
          ))}
        </Row>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query Blog($slug: String!) {
    post: ghostPost(slug: { eq: $slug }) {
      title
      slug
      uuid
      feature_image
      created_at
      updated_at
      published_at
      html
    }
    otherPosts: allGhostPost(
      limit: 3
      sort: { order: DESC, fields: [published_at] }
    ) {
      edges {
        node {
          title
          slug
          uuid
          feature_image
          featured
          excerpt
          published_at
          primary_tag {
            slug
            name
          }
          primary_author {
            slug
            name
          }
        }
      }
    }
  }
`
