import React from 'react'
import { Global } from '@emotion/core'
import css from '@styled-system/css'
import { Box } from 'components/primitives'

const typography = props => {
  return {
    '[data-blog] h1, [data-blog] h2, [data-blog] h3, [data-blog] h4, [data-blog] h5, [data-blog] h6': {
      color: 'text',
      lineHeight: 'heading',
      fontWeight: 'heavy',
      fontSize: 4,
      mb: 2,
      mt: 5
    },
    '[data-blog] p': {
      lineHeight: 1.625,
      fontSize: 18,
      mb: 3
    },
    '[data-blog] li': {
      lineHeight: 1.625,
      fontSize: 18
    },
    '[data-blog] hr': {
      my: 3,
      mx: 0,
      color: 'muted',
      bg: 'currentColor',
      border: 0
    },
    '[data-blog] hr:not([size])': {
      height: 1
    },
    '[data-blog] a': {
      color: 'blue',
      fontFamily: 'sans',
      textDecoration: 'none',
      bg: 'transparent'
    },
    '[data-blog] small': {
      fontSize: 1
    },
    '[data-blog] mark': {
      bg: 'lightYellow'
    },
    '[data-blog] table': {
      width: '100%',
      mb: 3,
      color: 'text',
      verticalAlign: 'top'
    },
    '[data-blog] blockquote': {
      my: 4,
      p: 4,
      bg: 'lightBlue'
    },
    '[data-blog] blockquote p': {
      m: 0
    },
    // '[data-blog] img': { width: '100%' },
    '[data-blog] ol, [data-blog] ul': {
      listStyle: 'initial',
      pl: 24
    },
    '[data-blog] ol, [data-blog] ul, [data-blog] dl': {
      mt: 0,
      mb: 3
    },
    '[data-blog] ol ol, [data-blog] ul ul, [data-blog] ol ul, [data-blog] ul ol': {
      mb: 0
    },
    '[data-blog] .footnote-item > p': {
      mb: 0
    },
    '[data-blog] table th, [data-blog] table td': {
      p: 2,
      borderBottom: '1px solid',
      borderColor: 'muted'
    },
    '[data-blog] table tbody': {
      verticalAlign: 'inherit'
    },
    '[data-blog] table thead th': {
      verticalAlign: 'bottom',
      borderColor: 'text'
    },
    '[data-blog] table tbody + tbody': {
      borderTop: '2px solid',
      borderColor: 'muted'
    }
  }
}

const styles = theme => css(typography)(theme)

export default ({ html, ...props }) => {
  return (
    <React.Fragment>
      <Global styles={styles} />
      <Box
        as='section'
        data-blog={true}
        dangerouslySetInnerHTML={{ __html: html }}
        {...props}
      />
    </React.Fragment>
  )
}
